<template>
  <g>
    <!-- Meeting line -->
    <line
      :x1="meetingLine.from.x"
      :y1="meetingLine.from.y"
      :x2="meetingLine.to.x"
      :y2="meetingLine.to.y"
      stroke="#000000"
      stroke-width="4"
    ></line>

    <!-- Meeting dates -->
    <g :transform="meetingCoordinates.container">
      <a :href="url(`meetings/${meeting.meeting_id}`)">
        <text
          :font-family="options.typography.text.fontFamily"
          font-size="15px"
          width="150px"
          :text-anchor="meetingCoordinates.textAnchor"
        >
          {{ meeting.title }}
        </text>
      </a>

      <text
        :font-family="options.typography.text.fontFamily"
        font-size="12px"
        width="150px"
        dy="15px"
        :text-anchor="meetingCoordinates.textAnchor"
      >
        {{ meeting.subtitle }}
      </text>
    </g>
  </g>
</template>

<script>
import { getDate, getDaysInMonth } from "date-fns";
import {
  SLICE_WIDTH,
  deg2rad,
  polarToCartesian,
} from "../annualCycleMathHelpers.js";

const TOOTH_LENGTH = 15;

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },

    coordinates: {
      type: Object,
      required: true,
    },

    meeting: {
      type: Object,
      required: true,
    },

    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    meetingLine() {
      const date = new Date(this.date);
      const monthNumber = date.getMonth();

      let angle =
        this.coordinates.startingAngle +
        (getDate(date) / getDaysInMonth(date)) * SLICE_WIDTH +
        SLICE_WIDTH * monthNumber;

      const from = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        this.coordinates.radius,
        angle
      );

      const to = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        this.coordinates.radius + TOOTH_LENGTH,
        angle
      );

      return {
        from: from,
        to: to,
      };
    },

    meetingCoordinates() {
      const date = new Date(this.date);
      const radius = this.coordinates.radius;

      const monthNumber = date.getMonth();
      let startDegrees =
        this.coordinates.startingAngle + SLICE_WIDTH * monthNumber;

      let deg = (getDate(date) / getDaysInMonth(date)) * SLICE_WIDTH;
      deg += startDegrees - 90;

      const angle = deg2rad(deg);
      let x = this.coordinates.centerX + radius * Math.cos(angle);
      let y = this.coordinates.centerY + radius * Math.sin(angle);

      deg = Math.abs((deg + 360) % 360);

      const textOffset = this.options.typography.margin * 1.5;
      x += (90 <= deg && deg <= 270 ? -textOffset : textOffset) * 2;
      y += (0 <= deg && deg <= 180 ? textOffset : -textOffset) * 2;

      const textAnchor = 90 <= deg && deg <= 270 ? "end" : "start";

      return {
        container: `translate(${x}, ${y})`,
        text: `rotate(${deg})`,
        textAnchor: textAnchor,
      };
    },
  },
};
</script>
