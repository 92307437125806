<template>
  <div>
    <evaluation-question-graph
      type="bar"
      class="mb-3"
      :question-data="questionData"
    />

    <template v-if="Object.keys(questionData.possible_answers).length > 0">
      <p class="mb-1 ml-2 mb-2">
        {{
          questionData.possible_answers[1].length > 0
            ? `1 = ${questionData.possible_answers[1]}`
            : ""
        }}
        <span class="mr-3" />
        {{
          questionData.possible_answers[2].length > 0
            ? `5 = ${questionData.possible_answers[2]}`
            : ""
        }}
      </p>
    </template>

    <table class="table">
      <tbody>
        <tr>
          <th class="col-shrink">
            {{ $t("views.companies.evaluation_forms.reports.results.average") }}
          </th>

          <td class="text-right">
            {{ questionData.average ? questionData.average : "-" }}
          </td>
        </tr>

        <tr>
          <th class="col-shrink">
            {{
              $t(
                "views.companies.evaluation_forms.reports.results.standard_deviation"
              )
            }}
          </th>

          <td class="text-right">
            {{ questionData.standard_deviation || "-" }}
          </td>
        </tr>

        <tr>
          <th>
            {{ $t("views.companies.evaluation_forms.reports.results.mean") }}
          </th>

          <td class="text-right">
            {{ questionData.mean ? questionData.mean : "-" }}
          </td>
        </tr>

        <tr>
          <th>
            {{
              $t(
                "views.companies.evaluation_forms.reports.results.typical_value"
              )
            }}
          </th>

          <td class="text-right">
            <template v-if="Object.keys(questionData.typical_value).length > 0">
              <template
                v-for="(value, key) in questionData.typical_value"
                :key="`question-${questionData.id}-${key}-typical-value`"
              >
                <p>{{ key }} ({{ value }})</p>
              </template>
            </template>

            <span v-else>-</span>
          </td>
        </tr>

        <tr>
          <th class="col-shrink">
            {{
              $t(
                "views.companies.evaluation_forms.reports.results.not_answered"
              )
            }}
          </th>

          <td class="text-right">{{ numberBlankAnswers }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EvaluationQuestionGraph from "./QuestionGraph.vue";

export default {
  components: {
    EvaluationQuestionGraph,
  },

  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    // Number of users who have sent in answers but left this question blank
    numberBlankAnswers() {
      return (
        this.questionData.number_finished_users -
        this.questionData.number_present_answers
      );
    },
  },
};
</script>
