<template>
  <div v-if="allMeetingsCount > 0">
    <header class="mb-4 d-print-none">
      <h1>{{ $t("models.annual_cycle.board_year") }}</h1>
    </header>

    <div class="card mb-3 mb-md-2">
      <div class="card-header border-bottom-0 rounded d-print-none">
        <div class="row d-flex justify-content-end no-gutters">
          <div class="col-12 col-md-auto mb-2 mb-md-0">
            <be-button
              v-be-modal.role-filter
              variant="outline-primary"
              class="d-none d-md-inline-block"
              :class="{ 'mr-2': !canCreateAnnualCycle }"
            >
              {{ $t("components.annual_cycles.year_plan.filter_roles_title") }}
            </be-button>

            <be-button
              v-if="canCreateAnnualCycle"
              variant="primary"
              class="mr-2"
              :href="url('/annual_cycle/new')"
            >
              {{
                $t("components.annual_cycles.introduction_year_plan.new_plan")
              }}
            </be-button>
          </div>

          <div class="col-12 col-md-auto mb-2 mb-md-0">
            <be-form-datepicker
              v-model="currentYear"
              type="year"
              :clearable="false"
              class="font-size-base"
              inline
              @input="changeYear"
            />
          </div>

          <div class="col-12 col-md-auto">
            <be-dropdown ellipsis>
              <be-dropdown-item v-be-modal.pdf-configuration>
                {{ exportModalTitle }}
              </be-dropdown-item>
            </be-dropdown>

            <export-modal
              id="pdf-configuration"
              :export-types="['annual_cycle_pdf']"
              :title="exportModalTitle"
              :export-params="{
                year: new Date(currentYear.toString()),
                filtered_roles: activeRoleFilters,
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-5 d-none d-md-block d-print-block">
      <div class="card-body">
        <div class="mb-2">
          <label>
            {{ $t("components.annual_cycles.year_plan.selected_filters") }}:
          </label>

          <be-badge
            v-for="roleFilter in activeRoleFilters"
            :key="`role-${roleFilter}`"
            variant="light"
            class="mr-1"
          >
            {{ $t(`models.annual_cycle.filter_roles.${roleFilter}`) }}
          </be-badge>

          <em v-if="activeRoleFilters && activeRoleFilters.length == 0">
            {{ $t("components.annual_cycles.year_plan.no_filters_selected") }}
          </em>
        </div>

        <companies-annual-cycle
          :dates="yearPlanSettings.dates"
          :initial-board-year-start="yearPlanSettings.board_year_start"
          :initial-day-of-year="yearPlanSettings.day_of_year"
          :initial-year-start="yearPlanSettings.year_start"
          :year="yearPlanSettings.year"
          :filtered-roles="activeRoleFilters"
          @year-updated="changeYear"
        />
      </div>
    </div>

    <year-plan-meetings
      v-if="meetingsInYear.length > 0"
      :meetings="meetingsInYear"
      :year="yearPlanSettings.year"
      class="mb-2"
    />

    <template v-else>
      <year-plan-introduction
        class="d-print-none"
        :year="yearPlanSettings.year"
      />

      <be-alert class="d-none d-print-block" variant="info">
        {{ $t("home.company_meetings.no_meetings") }}
      </be-alert>
    </template>

    <role-filter-modal
      :role-filters="roleFilters"
      @updated-roles="setActiveRoleFilters"
    />

    <div class="d-flex align-items-center">
      <em class="small">
        {{ formattedPeriod(yearPlanSettings.date_start, "period_start_end") }}
      </em>

      <span class="mx-2 text-muted">•</span>

      <em class="small">
        {{
          formattedPeriod(
            yearPlanSettings.board_year_start,
            "board_year_start_end"
          )
        }}
      </em>
    </div>
  </div>

  <year-plan-introduction v-else />
</template>

<script>
import CompaniesAnnualCycle from "./AnnualCycleShow.vue";
import YearPlanMeetings from "./YearPlanMeetings.vue";
import YearPlanIntroduction from "./YearPlanIntroduction.vue";
import RoleFilterModal from "./modals/RoleFilterModal.vue";
import ExportModal from "@/components/shared/export_modal/ExportModal.vue";

import { sub, add, getYear } from "date-fns";

export default {
  components: {
    CompaniesAnnualCycle,
    YearPlanMeetings,
    YearPlanIntroduction,
    RoleFilterModal,
    ExportModal,
  },

  props: {
    companyMeetingsCount: {
      type: Number,
      required: true,
    },

    meetings: {
      type: Array,
      required: true,
    },

    initialYearPlanSettings: {
      type: Object,
      required: true,
    },

    roleFilters: {
      type: Object,
      required: true,
    },

    initialRoleFilters: {
      type: Array,
      required: false,
      default: null,
    },

    canCreateAnnualCycle: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      allMeetingsCount: this.companyMeetingsCount,

      currentYear:
        new Date(this.initialYearPlanSettings.year_as_date).getFullYear() ||
        new Date().getFullYear(),

      exportYearAsDate: this.initialYearPlanSettings.year_as_date || new Date(),
      meetingsInYear: this.meetings,
      yearPlanSettings: this.initialYearPlanSettings,
      activeRoleFilters: [],
    };
  },

  computed: {
    exportYear() {
      return getYear(new Date(this.exportYearAsDate));
    },

    exportModalTitle() {
      return this.$t("buttons.titles.export_w_title", {
        title: this.$t("models.annual_cycle.board_year").toLowerCase(),
      });
    },
  },

  mounted() {
    this.$store.commit("meetings/loadMeetings", this.meetings);
    if (this.initialRoleFilters) {
      this.setActiveRoleFilters(this.initialRoleFilters);
    }
  },

  methods: {
    async changeYear(year) {
      // Can come in as a year directly (2020) or as a date (1 Jan 2020..)
      let formattedYear = Number.isInteger(year)
        ? year
        : getYear(new Date(year));

      try {
        const response = await axios.get(this.url("/annual_cycle"), {
          params: { year: formattedYear },
        });

        this.allMeetingsCount = response.data.company_meetings_count;
        this.meetingsInYear = response.data.meetings_in_specific_year;
        this.yearPlanSettings = response.data.year_plan_settings;
        this.currentYear = formattedYear;
      } catch (error) {
        this.handleError(error);
      }
    },

    formattedPeriod(startDate, translationKey) {
      let endDate = sub(add(new Date(startDate), { years: 1 }), { days: 1 });

      return this.$i18n.t(
        `components.annual_cycles.year_plan.${translationKey}`,
        {
          start_date: this.$d(new Date(startDate), "monthDayShort"),
          end_date: this.$d(new Date(endDate), "monthDayShort"),
        }
      );
    },

    setActiveRoleFilters(roleFilters) {
      this.activeRoleFilters = roleFilters;
    },
  },
};
</script>
