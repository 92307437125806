<template>
  <div
    v-if="answerCount > 0"
    :id="selector"
    class="wz-graph wz-graph-area"
  ></div>

  <div v-else>{{ $t("components.evaluation_forms.graphs.no_data") }}</div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { EventBus } from "@/event-bus";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },

    questionData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selector: `question-graph-${this.questionData.id}`,
    };
  },

  computed: {
    answerCount() {
      return Object.keys(this.questionData.answers).length;
    },
  },

  mounted() {
    if (this.answerCount > 0) {
      if (this.type == "bar") {
        this.createBarChart();
      } else {
        this.createPieChart();
      }
    }
  },

  methods: {
    answerData() {
      let finalData = [];

      Object.keys(this.questionData.answers).forEach((answerKey) => {
        finalData.push({
          category: answerKey,
          value: this.questionData.answers[answerKey],
        });
      });

      return finalData;
    },

    createPieChart() {
      let chart = am4core.create(this.selector, am4charts.PieChart);

      // Add data and add general settings
      chart.data = this.answerData();

      // Create series
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "category";
      pieSeries.labels.template.disabled = true;

      chart.legend = new am4charts.Legend();
      chart.legend.scrollable = true;
      chart.legend.labels.template.truncate = true;
      chart.legend.labels.template.maxWidth = 110;

      chart.events.on("ready", () =>
        EventBus.emit("EVALUATION-QUESTION-INITIALIZED", this.questionData.id)
      );
    },

    createBarChart() {
      let chart = am4core.create(this.selector, am4charts.XYChart);

      // Add data and add general settings
      chart.data = this.answerData();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.wrap = true;
      categoryAxis.renderer.labels.template.maxWidth = 120;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.maxPrecision = 0;

      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "category";

      //Enable responsive features
      chart.responsive.enabled = true;
      chart.responsive.useDefault = false;

      chart.responsive.rules.push({
        relevant: function (target) {
          if (target.pixelWidth <= 400) {
            return true;
          }

          return false;
        },

        state: function (target, stateId) {
          if (target instanceof am4charts.Chart) {
            var state = target.states.create(stateId);
            state.properties.paddingTop = 10;
            return state;
          }

          return null;
        },
      });

      chart.events.on("ready", () =>
        EventBus.emit("EVALUATION-QUESTION-INITIALIZED", this.questionData.id)
      );
    },
  },
};
</script>
