<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>
            {{
              $t(
                "views.companies.evaluation_forms.reports.open_question_results.open_question_table.answer"
              )
            }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(value, index) in questionData.attachments">
          <tr
            v-if="value != ''"
            :key="`question-${questionData.id}-answer-${index}-attachment`"
          >
            <td>{{ value }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
