<template>
  <div class="card mb-2">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("models.annual_cycle.board_year") }}
      </h5>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-6 order-2 order-lg-1">
          <p class="mb-4">
            {{
              $t("components.annual_cycles.introduction_year_plan.description")
            }}
          </p>

          <be-button
            :href="url(`/annual_cycle/new?year=${year}`)"
            variant="primary"
          >
            {{ $t("models.annual_cycle.new") }}
          </be-button>
        </div>

        <div class="col-12 col-lg-6 order-1 order-lg-2">
          <be-img
            :src="imageSrc('onboarding/intro/year_plan.png')"
            class="mb-3 mb-lg-0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    year: {
      type: Number,
      required: false,
      default: new Date().getFullYear(),
    },
  },
};
</script>
