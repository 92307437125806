<template>
  <div>
    <div
      v-for="(section, sectionIndex) in sectionsWithQuestions"
      :id="`section-${section.id}`"
      :key="`section-${section.id}`"
      class="card mb-2"
    >
      <div class="card-header">
        <h3>
          {{ `${sectionIndex + 1}. ${section.title}` }}
        </h3>
      </div>

      <div class="card-body">
        <evaluation-report-question
          v-for="(question, questionIndex) in Object.values(section.questions)"
          :key="`section-${section.id}-question-${question.id}`"
          :question-data="question"
          :comments="commentsByQuestionId[question.id] || []"
          :readonly="readonly"
          :section-index="sectionIndex"
          :question-index="questionIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EvaluationReportQuestion from "./EvaluationReportQuestion.vue";

import isEqual from "lodash/isEqual";
import { EventBus } from "@/event-bus";

export default {
  components: {
    EvaluationReportQuestion,
  },

  props: {
    sectionData: {
      type: Object,
      required: true,
    },

    questionComments: {
      type: Array,
      required: false,
      default: () => [],
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      questionIdsInitialized: new Set(),
    };
  },

  computed: {
    sectionsWithQuestions() {
      return Object.values(this.sectionData).filter((section) =>
        Object.values(section.questions).some(
          (question) => question.included_in_report
        )
      );
    },

    // Get all question ids where there will be a graph
    questionIdsWithGraph() {
      const ids = new Set();

      this.sectionsWithQuestions.forEach((section) => {
        Object.values(section.questions).forEach((question) => {
          if (
            question.included_in_report &&
            question.type != "open" &&
            question.number_present_answers > 0 &&
            question.show_results
          ) {
            ids.add(question.id);
          }
        });
      });

      return ids;
    },

    commentsByQuestionId() {
      const commentsByQuestionId = {};

      this.questionComments.forEach((comment) => {
        if (commentsByQuestionId[comment.commented_id]) {
          commentsByQuestionId[comment.commented_id].push(comment);
        } else {
          commentsByQuestionId[comment.commented_id] = [comment];
        }
      });

      return commentsByQuestionId;
    },
  },

  created() {
    if (this.questionIdsWithGraph.size === 0) {
      window.status = "ready_to_print";
      console.log("ready to print");
    } else {
      EventBus.on("EVALUATION-QUESTION-INITIALIZED", this.checkReady);
    }
  },

  methods: {
    checkReady(questionId) {
      this.questionIdsInitialized.add(questionId);

      if (isEqual(this.questionIdsInitialized, this.questionIdsWithGraph)) {
        window.status = "ready_to_print";
        console.log("ready to print");
      }
    },
  },
};
</script>
