<template>
  <div>
    <div class="row d-print-none">
      <div class="col-auto text-left">
        <be-button variant="light" @click="setYear(year - 1)">
          {{ year - 1 }}
        </be-button>
      </div>

      <div class="col text-center">
        <h3>{{ year }}</h3>

        <div v-if="year == thisYear" class="btn-group gap-0">
          <be-button
            :variant="
              dateState == 'from_board_year' ? 'primary' : 'outline-secondary'
            "
            @click="
              setState('from_board_year');
              dayOfYear = boardYearStart;
            "
          >
            {{ $t("components.annual_cycles.annual_cycle.from_board_year") }}
          </be-button>

          <be-button
            :variant="
              dateState == 'from_year' ? 'primary' : 'outline-secondary'
            "
            @click="
              setState('from_year');
              dayOfYear = 0;
            "
          >
            {{ $t("components.annual_cycles.annual_cycle.from_year") }}
          </be-button>

          <be-button
            :variant="
              dateState == 'from_today' ? 'primary' : 'outline-secondary'
            "
            @click="
              setState('from_today');
              dayOfYear = initialDayOfYear;
            "
          >
            {{ $t("components.annual_cycles.annual_cycle.from_today") }}
          </be-button>
        </div>
      </div>

      <div class="col-auto text-right">
        <be-button variant="light" @click="setYear(year + 1)">
          {{ year + 1 }}
        </be-button>
      </div>
    </div>

    <annual-cycle
      ref="annualCycle"
      :dates="dates"
      :period="{ start: year, end: year + 1 }"
      :day-of-year="dayOfYear"
      :filtered-roles="filteredRoles"
    />
  </div>
</template>

<script>
import AnnualCycle from "./AnnualCycle.vue";
import { getYear } from "date-fns";

export default {
  components: {
    AnnualCycle,
  },

  props: {
    dates: {
      type: Array,
      required: true,
    },

    initialBoardYearStart: {
      type: Number,
      required: true,
    },

    initialDayOfYear: {
      type: Number,
      required: true,
    },

    initialYearStart: {
      type: String,
      required: true,
    },

    year: {
      type: Number,
      required: true,
    },

    filteredRoles: {
      type: Array,
      required: true,
    },
  },

  emits: ["year-updated"],

  data() {
    return {
      dateState: "from_today",
      thisYear: getYear(new Date()),
      boardYearStart: this.initialBoardYearStart,
      yearStart: this.initalYearStart,
      dayOfYear: this.initialDayOfYear,
    };
  },

  methods: {
    render() {
      this.$refs["annual-cycle"].render();
    },

    setState(state) {
      this.dateState = state;
    },

    setYear(year) {
      this.$emit("year-updated", year);
    },
  },
};
</script>
