<template>
  <g :transform="eventCoordinates.container">
    <g :transform="eventCoordinates.inner">
      <text
        :font-family="options.typography.text.fontFamily"
        font-size="12px"
        :transform="eventCoordinates.text"
        width="150px"
        :text-anchor="eventCoordinates.textAnchor"
      >
        {{ event.title }}
      </text>
    </g>
  </g>
</template>

<script>
import { getDate, getDaysInMonth } from "date-fns";
import { SLICE_WIDTH, deg2rad } from "../annualCycleMathHelpers.js";

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },

    coordinates: {
      type: Object,
      required: true,
    },

    event: {
      type: Object,
      required: true,
    },

    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    eventCoordinates() {
      const dateObject = new Date(this.date);
      const radius = this.coordinates.radius;

      // Height of text box in px
      const textLocation = radius - this.options.typography.margin - 20;

      const monthNumber = dateObject.getMonth();
      let startDegrees =
        this.coordinates.startingAngle + SLICE_WIDTH * monthNumber;
      let deg =
        (getDate(dateObject) / getDaysInMonth(dateObject)) * SLICE_WIDTH;
      deg += startDegrees - 90;

      const angle = deg2rad(deg);
      const x = this.coordinates.centerX + textLocation * Math.cos(angle);
      const y = this.coordinates.centerY + textLocation * Math.sin(angle);

      deg = Math.abs((deg + 360) % 360);
      let textAnchor = "end";
      let innerTransform = "";
      if (90 <= deg && deg <= 270) {
        textAnchor = "start";
        innerTransform = "rotate(180)";
      }

      return {
        container: `translate(${x}, ${y})`,
        inner: innerTransform,
        text: `rotate(${deg})`,
        textAnchor: textAnchor,
      };
    },
  },
};
</script>
