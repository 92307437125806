<template>
  <div>
    <h2 class="d-print-none">
      {{ $t("models.annual_cycle.board_year_w_year", { year: year }) }}
    </h2>

    <div
      v-for="meeting in meetings"
      :key="`meeting-${meeting.id}`"
      class="card mb-2"
    >
      <div class="card-header d-flex justify-content-between">
        <h5 class="card-title align-self-center">
          <be-link
            :href="meeting.paths.base"
            class="text-decoration-none text-reset"
          >
            {{ meetingTitle(meeting) }}
          </be-link>
        </h5>

        <be-dropdown class="d-print-none" ellipsis>
          <template v-if="meetingEditable(meeting)">
            <be-dropdown-item :href="meeting.paths.edit">
              {{ $t("buttons.titles.edit") }}
            </be-dropdown-item>

            <be-dropdown-divider />
          </template>

          <be-dropdown-item :href="meeting.paths.calendar">
            {{
              $t(
                "components.annual_cycles.meeting_container.download_calendar_file"
              )
            }}
          </be-dropdown-item>
        </be-dropdown>
      </div>

      <div class="card-body">
        <time-and-location :meeting="meeting" disable-buttons />
      </div>
    </div>
  </div>
</template>

<script>
import TimeAndLocation from "@/components/meetings/tabs/TimeAndLocationTab.vue";

export default {
  components: {
    TimeAndLocation,
  },

  props: {
    meetings: {
      type: Array,
      required: false,
      default: () => [],
    },

    year: {
      type: Number,
      required: true,
    },
  },

  methods: {
    meetingEditable(meeting) {
      return !meeting.process.minutes_approved && meeting.policy.edit;
    },

    meetingTitle(meeting) {
      return this.$i18n.t("companies.dashboard.header.meeting_header", {
        number: meeting.number,
        title: meeting.title,
      });
    },
  },
};
</script>
