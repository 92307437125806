<template>
  <g>
    <path
      :d="monthCoordinates"
      stroke="var(--black)"
      stroke-width="1"
      :fill="index % 2 == 0 ? 'var(--primary)' : 'var(--white)'"
    ></path>

    <g :transform="monthTitleCoordinates.outer">
      <g :transform="monthTitleCoordinates.inner">
        <text
          :font-family="options.typography.heading.fontFamily"
          :font-size="options.typography.heading.fontSize"
          :fill="index % 2 == 0 ? 'var(--white)' : 'var(--black)'"
          :transform="monthTitleCoordinates.text"
          text-anchor="middle"
          width="150px"
          dy="3px"
        >
          {{ monthTitle }}
        </text>
      </g>
    </g>
  </g>
</template>

<script>
import {
  SLICE_WIDTH,
  deg2rad,
  describeArc,
} from "../annualCycleMathHelpers.js";

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },

    coordinates: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    monthCoordinates() {
      const deg = this.coordinates.startingAngle + SLICE_WIDTH * this.index;

      return describeArc(
        this.coordinates.centerX,
        this.coordinates.centerY,
        this.coordinates.radius,
        deg,
        deg + SLICE_WIDTH
      );
    },

    monthTitleCoordinates() {
      const radius = this.coordinates.radius;
      const textLocation = radius - 10; // Height of text box in px

      let startDegrees =
        this.coordinates.startingAngle + SLICE_WIDTH * this.index;
      const angleStart = startDegrees;
      const angleEnd = startDegrees + SLICE_WIDTH;

      let deg = angleStart + (angleEnd - angleStart) / 2 - 90;

      const angle = deg2rad(deg);
      const x = this.coordinates.centerX + textLocation * Math.cos(angle);
      const y = this.coordinates.centerY + textLocation * Math.sin(angle);

      deg = Math.abs((360 + deg) % 360);
      const rotateDeg = 0 <= deg && deg <= 180 ? -90 : 90;

      return {
        outer: `translate(${x}, ${y})`,
        inner: `rotate(${rotateDeg})`,
        text: `rotate(${deg})`,
      };
    },

    monthTitle() {
      let monthDate = new Date();

      // Set the date to 10 to avoid showing the wrong
      // month title at the start or end of the month due
      // to time zone differences and different amount of
      // days depending on the current month.
      monthDate.setDate(10);
      monthDate.setMonth(this.index);

      // Return month as "Feb", "Mar", "feb.", "mars" etc.
      return this.$d(monthDate, "month");
    },
  },
};
</script>
