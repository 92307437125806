export const SLICE_WIDTH = 30;

/**
 * Math helpers
 */
export const deg2rad = (deg) => {
  return (deg * Math.PI) / 180;
};

export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

  return [
    // eslint-disable-next-line prettier/prettier
    "M",
    x,
    y,
    // eslint-disable-next-line prettier/prettier
    "L",
    start.x,
    start.y,
    // eslint-disable-next-line prettier/prettier
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
    "z",
  ].join(" ");
};
