// Find simple-form fields with errors
// If they are changed, remove the error messages.
window.addEventListener("DOMContentLoaded", () => {
  const events = ["keyup", "change"];
  const elements = document.querySelectorAll(
    "form.simple_form input.is-invalid"
  );

  elements.forEach((element) => {
    events.forEach((event) => {
      element.addEventListener(event, () => {
        element.classList.remove("is-invalid");
      });
    });
  });
});
