<template>
  <div :id="`question-${questionData.id}`" class="mb-5 page-break-inside-avoid">
    <h4 class="mb-5">
      {{ `${sectionIndex + 1}.${questionIndex + 1} ${questionData.title}` }}
    </h4>

    <template v-if="questionData.number_present_answers > 0">
      <template v-if="questionData.show_results">
        <ranged-question
          v-if="questionData.type === 'ranged'"
          :question-data="questionData"
        />

        <choice-question
          v-else-if="
            ['multiple_choices', 'one_choice'].includes(questionData.type)
          "
          :question-data="questionData"
        />

        <open-question v-else :question-data="questionData" />
      </template>

      <p v-else class="help-block">
        {{
          $t(
            "views.companies.evaluation_forms.reports.open_question_results.not_showing_confidential_answers"
          )
        }}
      </p>
    </template>

    <div v-else class="mb-2">
      {{
        $t(
          "views.companies.evaluation_forms.reports.open_question_results.open_question_table.no_answers_present"
        )
      }}
    </div>

    <div v-if="!readonly" class="d-print-none">
      <comments-list
        :comments="comments"
        :commentable-object="{ sgid: questionData.sgid }"
        show-toggle
      />
    </div>
  </div>
</template>

<script>
import ChoiceQuestion from "./ChoiceQuestion.vue";
import OpenQuestion from "./OpenQuestion.vue";
import RangedQuestion from "./RangedQuestion.vue";
import CommentsList from "@/components/shared/comments/CommentsList.vue";

export default {
  components: {
    CommentsList,
    ChoiceQuestion,
    OpenQuestion,
    RangedQuestion,
  },

  props: {
    questionData: {
      type: Object,
      required: true,
    },

    comments: {
      type: Array,
      required: false,
      default: () => [],
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    sectionIndex: {
      type: Number,
      required: true,
    },

    questionIndex: {
      type: Number,
      required: true,
    },
  },
};
</script>
