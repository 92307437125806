<template>
  <div>
    <evaluation-question-graph
      v-if="Object.keys(questionData.answers).length <= 5"
      type="pie"
      class="mb-3"
      :question-data="questionData"
    />

    <evaluation-question-graph
      v-else
      type="bar"
      class="mb-3"
      :question-data="questionData"
    />

    <table class="table">
      <tbody>
        <tr>
          <th>
            {{
              $t(
                "views.companies.evaluation_forms.reports.results.typical_value"
              )
            }}
          </th>

          <td class="text-right">
            <template
              v-if="Object.keys(questionData.typical_value || {}).length > 0"
            >
              <template
                v-for="(value, key) in questionData.typical_value"
                :key="`question-${questionData.id}-${key}-typical-value`"
              >
                <p>{{ key }} ({{ value }})</p>
              </template>
            </template>

            <p v-else>-</p>
          </td>
        </tr>

        <tr>
          <th>
            {{
              $t(
                "views.companies.evaluation_forms.reports.results.number_of_answers"
              )
            }}
          </th>

          <td class="text-right">
            {{ questionData.number_present_answers }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EvaluationQuestionGraph from "./QuestionGraph.vue";

export default {
  components: {
    EvaluationQuestionGraph,
  },

  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
