<template>
  <div ref="annual-cycle-outer-container" class="py-4">
    <svg
      v-if="width > 0"
      ref="annual-cycle-container"
      :width="`${width}px`"
      height="679px"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Months -->
      <month-part
        v-for="(month, index) in months"
        :key="`month-${month}`"
        :options="options"
        :coordinates="coordinates"
        :index="index"
      />

      <!-- Teeth -->
      <tooth-part
        v-for="(month, index) in months"
        :key="`teeth-${month}`"
        :options="options"
        :coordinates="coordinates"
        :index="index"
      />

      <!-- Base Plate -->
      <circle
        :cx="coordinates.centerX"
        :cy="coordinates.centerY"
        :r="coordinates.radius - 20"
        stroke="#000000"
        stroke-width="1"
        fill="#ffffff"
      />

      <!-- Outer Dates -->
      <g v-if="filteredDates[0].length > 0">
        <template v-for="event in filteredDates[0]">
          <outer-event
            v-for="date in event.dates"
            :key="`event-0-${date}`"
            :options="options"
            :coordinates="coordinates"
            :event="event"
            :date="date"
          />
        </template>
      </g>

      <!-- Inner Dates -->
      <g v-if="filteredDates[1].length > 0">
        <template v-for="event in filteredDates[1]">
          <inner-event
            v-for="date in event.dates"
            :key="`event-1-${date}`"
            :options="options"
            :coordinates="coordinates"
            :event="event"
            :date="date"
          />
        </template>
      </g>

      <!-- Inner Circle -->
      <circle
        :cx="coordinates.centerX"
        :cy="coordinates.centerY"
        :r="coordinates.radius * 0.5"
        stroke="#000000"
        stroke-width="1"
        fill="transparent"
      />

      <!-- Meetings -->
      <template v-if="filteredDates[2].length > 0">
        <template v-for="(meeting, meetingIndex) in filteredDates[2]">
          <meeting-part
            v-for="(date, index) in meeting.dates"
            :key="`date-2-${meetingIndex}-${index}`"
            :options="options"
            :coordinates="coordinates"
            :meeting="meeting"
            :date="date"
          />
        </template>
      </template>

      <!-- Center Circle -->
      <circle
        :cx="coordinates.centerX"
        :cy="coordinates.centerY"
        :r="coordinates.radius * 0.15"
        stroke="#000000"
        stroke-width="1"
        fill="#ffffff"
      />

      <!-- Fiscal year end -->
      <line
        :x1="fiscalYearLine.from.x"
        :y1="fiscalYearLine.from.y"
        :x2="fiscalYearLine.to.x"
        :y2="fiscalYearLine.to.y"
        stroke="rgba(0, 0, 0, 0.3)"
        stroke-width="3"
      />

      <g
        :transform="`translate(${fiscalYearLabelPosition.x}, ${fiscalYearLabelPosition.y})`"
      >
        <text
          :font-family="options.typography.text.fontFamily"
          font-size="15px"
          width="150px"
          :text-anchor="fiscalYearLabelPosition.textAnchor"
        >
          {{ $t("annual_cycles.layers.titles.financial_year") }}
        </text>
      </g>

      <!-- Today line -->
      <line
        :x1="todayLine.from.x"
        :y1="todayLine.from.y"
        :x2="todayLine.to.x"
        :y2="todayLine.to.y"
        stroke="rgba(255, 0, 0, 0.5)"
        stroke-width="2"
      />

      <!-- Year labels -->
      <g dominant-baseline="middle">
        <g :transform="yearLabel('left').outer">
          <g :transform="yearLabel('left').inner">
            <text
              :font-family="options.typography.text.fontFamily"
              font-size="10px"
              :transform="yearLabel('left').textTransform"
              :text-anchor="yearLabel('left').textAnchor"
              width="150px"
              :dx="yearLabel('left').dx"
            >
              {{ period.start }}
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { format, getDayOfYear } from "date-fns";
import { deg2rad, polarToCartesian } from "./annualCycleMathHelpers.js";

import MonthPart from "./parts/MonthPart.vue";
import ToothPart from "./parts/ToothPart.vue";
import OuterEvent from "./parts/OuterEvent.vue";
import InnerEvent from "./parts/InnerEvent.vue";
import MeetingPart from "./parts/MeetingPart.vue";

const MONTHS = Array.from({ length: 12 }, (month, i) =>
  format(new Date(null, i + 1, null), "MMM")
);

const FONT_FAMILY = getComputedStyle(document.documentElement).getPropertyValue(
  "--font-family-sans-serif"
);

const OPTIONS = {
  borderWidth: 1,

  typography: {
    margin: 5,

    heading: {
      fontFamily: FONT_FAMILY,
      fontSize: "12px",
    },

    title: {
      fontFamily: FONT_FAMILY,
      fontSize: "15px",
    },

    subtitle: {
      fontFamily: FONT_FAMILY,
      fontSize: "12px",
    },

    text: {
      fontFamily: FONT_FAMILY,
      fontSize: "12px",
    },
  },
};

export default {
  components: {
    MonthPart,
    ToothPart,
    OuterEvent,
    InnerEvent,
    MeetingPart,
  },

  props: {
    dates: {
      type: Array,
      required: true,
    },

    period: {
      type: Object,
      required: true,
    },

    dayOfYear: {
      type: Number,
      required: true,
    },

    filteredRoles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      width: 0,
      months: MONTHS,
      options: OPTIONS,
      roles: this.filteredRoles,
      filteredDates: [[], [], []],

      coordinates: {
        startingAngle: 0,
        centerX: 0,
        centerY: 0,
        height: 0,
        width: 0,
        radius: 20,
      },
    };
  },

  computed: {
    fiscalYearLine() {
      const angle = this.coordinates.startingAngle;

      const from = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        0,
        angle
      );

      const to = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        this.coordinates.radius,
        angle
      );

      return {
        from: from,
        to: to,
      };
    },

    fiscalYearLabelPosition() {
      let deg = this.coordinates.startingAngle - 90;
      const radius = this.coordinates.radius + 10;

      // Calculate the position of the container
      const angle = deg2rad(deg);
      let x = this.coordinates.centerX + radius * Math.cos(angle);
      let y = this.coordinates.centerY + radius * Math.sin(angle);

      deg = Math.abs((deg + 360) % 360);

      // Offset for the correct quadrant
      const textOffset = 5;
      x += (90 <= deg && deg <= 270 ? -textOffset : textOffset) * 2;
      y += (0 <= deg && deg <= 180 ? textOffset : -textOffset) * 2;

      const textAnchor = 90 <= deg && deg <= 270 ? "end" : "start";

      return {
        x: x,
        y: y,
        textAnchor: textAnchor,
      };
    },

    todayLine() {
      const dayOfYear = getDayOfYear(new Date());
      const angle = this.coordinates.startingAngle + (dayOfYear / 365) * 360;

      const from = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        0,
        angle
      );

      const to = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        this.coordinates.radius,
        angle
      );

      return {
        from: from,
        to: to,
      };
    },
  },

  watch: {
    filteredRoles: {
      handler(newVal) {
        this.roles = newVal;
        this.calculateDates();
      },

      deep: true,
    },

    dates: {
      handler() {
        this.calculateDates();
      },

      deep: true,
    },

    dayOfYear() {
      this.setStartingAngle();
    },
  },

  mounted() {
    // We need next tick here since we need
    // the root element to be rendered first
    this.$nextTick(() => {
      this.setupAnnualCycle();
    });
  },

  methods: {
    setupAnnualCycle() {
      if (!this.$refs["annual-cycle-outer-container"]) {
        console.warn("Failed to find the outer container! Aborting...");
        this.width = 0;
        return;
      }

      // Remove side padding and set width
      this.width = this.$refs["annual-cycle-outer-container"].clientWidth - 15;

      // We need next tick here since we need
      // the svg element to be rendered first
      // This is triggered by the line above.
      this.$nextTick(() => {
        this.coordinates.width =
          this.$refs["annual-cycle-container"].width.baseVal.value -
          this.options.borderWidth * 2;
        this.coordinates.height =
          this.$refs["annual-cycle-container"].height.baseVal.value -
          this.options.borderWidth * 2;

        this.coordinates.centerX =
          this.coordinates.width / 2 + this.options.borderWidth;
        this.coordinates.centerY =
          this.coordinates.height / 2 + this.options.borderWidth;

        this.coordinates.radius = (this.coordinates.width * 0.6) / 2 - 10;

        this.setStartingAngle();
      });
    },

    calculateDates() {
      this.filteredDates = [[], [], []];

      this.dates.forEach((datePart, index) => {
        datePart.forEach((date) => {
          const hasRole = date.target.some((filteredRole) => {
            return this.roles.includes(filteredRole);
          });

          if (hasRole) {
            this.filteredDates[index].push(date);
          }
        });
      });
    },

    setStartingAngle() {
      this.coordinates.startingAngle = -(this.dayOfYear / 365) * 360;
    },

    /**
     * Year labels
     */
    yearLabel(position) {
      const radius = this.coordinates.radius;
      const textLocation = radius + 5; // Height of text box in px

      let deg = this.coordinates.startingAngle - 90;
      const angle = deg2rad(deg);
      const x = this.coordinates.centerX + textLocation * Math.cos(angle);
      const y = this.coordinates.centerY + textLocation * Math.sin(angle);

      deg = Math.abs((deg + 360) % 360);
      const shouldFlip = 0 <= deg && deg <= 180;
      const isLeftPosition =
        (position == "left" && shouldFlip) ||
        (position == "right" && !shouldFlip);

      const rotateDeg = shouldFlip ? -90 : 90;
      const dx = isLeftPosition ? "-3px" : "3px";
      const textAnchor = isLeftPosition ? "end" : "start";

      return {
        outer: `translate(${x}, ${y})`,
        inner: `rotate(${rotateDeg})`,
        textTransform: `rotate(${deg})`,
        textAnchor: textAnchor,
        dx: dx,
      };
    },
  },
};
</script>
