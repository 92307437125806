<template>
  <div class="custom-control custom-checkbox">
    <input
      :id="`role-${role}`"
      v-model="checked"
      type="checkbox"
      class="custom-control-input"
      @change="toggleRole"
    />

    <label
      class="custom-control-label font-weight-normal"
      :for="`role-${role}`"
    >
      {{ $t(`models.annual_cycle.filter_roles.${role}`) }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    role: {
      type: String,
      required: true,
    },

    preSelected: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["role-toggled"],

  data() {
    return {
      checked: this.preSelected,
    };
  },

  watch: {
    preSelected(newVal) {
      this.checked = newVal;
    },
  },

  methods: {
    toggleRole() {
      this.$emit("role-toggled", this.role);
    },
  },
};
</script>
