<template>
  <line
    :x1="teethCoordinates.from.x"
    :y1="teethCoordinates.from.y"
    :x2="teethCoordinates.to.x"
    :y2="teethCoordinates.to.y"
    stroke="#000000"
    stroke-width="1"
  ></line>
</template>

<script>
import { SLICE_WIDTH, polarToCartesian } from "../annualCycleMathHelpers.js";

const TOOTH_LENGTH = 10;

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },

    coordinates: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    teethCoordinates() {
      let angle = this.coordinates.startingAngle + SLICE_WIDTH * this.index;

      const from = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        this.coordinates.radius,
        angle
      );

      const to = polarToCartesian(
        this.coordinates.centerX,
        this.coordinates.centerY,
        this.coordinates.radius + TOOTH_LENGTH,
        angle
      );

      return {
        from: from,
        to: to,
      };
    },
  },
};
</script>
