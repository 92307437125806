<template>
  <be-modal
    id="role-filter"
    size="sm"
    ok-only
    ok-variant="primary"
    no-teleport
    :ok-title="$t('buttons.titles.close')"
    :title="$t('components.annual_cycles.year_plan.filter_roles_title')"
  >
    <div class="mb-2">
      <role-row
        v-for="role in roles"
        :key="`role-row-${role}`"
        ref="role-row"
        :role="role"
        :pre-selected="selectedRoles.includes(role)"
        @role-toggled="toggleRole"
      />
    </div>

    <be-button variant="outline-secondary" size="sm" @click="toggleAll">
      {{ toggleRolesButtonText }}
    </be-button>
  </be-modal>
</template>

<script>
import { mapGetters } from "vuex";
import RoleRow from "./RoleRow.vue";

const DEFAULT_SELECTED_ROLES = ["chairman", "ceo"];

export default {
  components: {
    RoleRow,
  },

  props: {
    roleFilters: {
      type: Object,
      required: true,
    },
  },

  emits: ["updated-roles"],

  data() {
    return {
      DEFAULT_SELECTED_ROLES: DEFAULT_SELECTED_ROLES,
      roles: [],
      allRolesSelected: false,
      selectedRoles: [],
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),

    toggleRolesButtonText() {
      if (this.allRolesSelected) {
        return this.$i18n.t("components.annual_cycles.year_plan.deselect_all");
      }

      return this.$i18n.t("components.annual_cycles.year_plan.select_all");
    },
  },

  created() {
    this.setDefaultSelectedRoles();
    this.setSelectedRoles();
  },

  mounted() {
    this.$emit("updated-roles", this.selectedRoles);
  },

  methods: {
    setDefaultSelectedRoles() {
      const membership = this.company.memberships.find(
        (membership) => membership.user.id == this.$currentUser.id
      );

      if (membership) {
        this.DEFAULT_SELECTED_ROLES = [
          membership.function,
          membership.role,
        ].filter((val) => !!val);
      }
    },

    setSelectedRoles() {
      return Object.keys(this.roleFilters).map((roleType) => {
        return this.roleFilters[roleType].map((role) => {
          if (this.DEFAULT_SELECTED_ROLES.includes(role)) {
            this.selectedRoles.push(role);
          }

          this.roles.push(role);
        });
      });
    },

    toggleAll() {
      this.allRolesSelected = !this.allRolesSelected;

      if (this.allRolesSelected) {
        this.selectedRoles = this.cloneDeep(this.roles);
      } else {
        this.selectedRoles = [];
      }

      this.$emit("updated-roles", this.selectedRoles);
    },

    toggleRole(updatedRole) {
      const idx = this.selectedRoles.findIndex((role) => role == updatedRole);

      if (idx > -1) {
        this.selectedRoles.splice(idx, 1);
      } else {
        this.selectedRoles.push(updatedRole);
      }

      this.$emit("updated-roles", this.selectedRoles);
    },
  },
};
</script>
